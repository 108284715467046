<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <v-row class="pa-12">
        <v-col
          cols="6"
          class="mx-auto px-8 py-12"
          style="border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 10px"
        >
          <v-form ref="form" v-model="valid">
            <v-row>
              <!-- Custom Id -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  v-model="formData.customInstituteId"
                  outlined
                  label="Custom Id (Must be Unique)"
                  class="form_element"
                  :rules="formRules.customInstituteId"
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>
              <!-- Fee Taking Type -->
              <v-col cols="12" class="d-flex">
                <v-autocomplete
                  v-model="formData.feeTakingType"
                  :items="feeTakingTypes"
                  outlined
                  label="Fee Taking Type"
                  class="form_element"
                  :rules="formRules.feeTakingType"
                ></v-autocomplete>
                <span class="text-red">*</span>
              </v-col>
              <!-- Institute Name -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  v-model="formData.instituteName"
                  outlined
                  label="Institute Name"
                  class="form_element"
                  :rules="formRules.instituteName"
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>
              <!-- Address -->
              <v-col cols="12" class="d-flex">
                <v-textarea
                  v-model="formData.instituteAddress"
                  outlined
                  label="Address"
                  class="form_element"
                  :rules="formRules.instituteAddress"
                ></v-textarea>
                <span class="text-red">*</span>
              </v-col>
              <!-- Admission Fee -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  v-model="formData.onlineAdmissionFee"
                  outlined
                  type="Number"
                  label="Admission Fee"
                  class="form_element"
                  :rules="formRules.onlineAdmissionFee"
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>
              <!-- Image -->
              <v-col cols="12" class="d-flex">
                <div class="w-100">
                  <v-file-input
                    label="Institute Photo"
                    append-icon="fa-upload"
                    prepend-icon=""
                    outlined
                    class="form_element w-100"
                    accept="image/*"
                    show-size
                    v-model="image"
                    @change="fileHandler($event)"
                  ></v-file-input>
                  <br />
                  <v-img
                    v-if="formData.imageUrl"
                    :src="formData.imageUrl"
                    width="100%"
                    contain
                    class=""
                  ></v-img>
                </div>
                <span class="text-red">*</span>
              </v-col>

              <!-- Buttons -->
              <v-col cols="12" class="text-center">
                <v-btn @click="saveBtnHandler" color="primary" class="mx-2">
                  {{ updateMode ? "Update" : "Save" }}
                </v-btn>
                <v-btn @click="clear" color="error" class="mx-2">Clear</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <DataTable
          :tableHeaders="headerArray"
          :tableData="institutes"
          :editIconValue="'fas fa-users'"
          :deleteIconValue="'fa-edit'"
          @editHandler="editInstituteHandler"
          @deleteHandler="editInstituteInformationHandler"
          class="w-100"
        />
      </v-row>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import * as slug from "../config/slug.js";
export default {
  beforeMount() {
    this.getAllInstitutes();
    this.getAllFeeTakingTypes();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      portalLink: "https://abedon.dcampusweb.com/application-form",
      valid: false,
      institutes: [],
      feeTakingTypes: [],
      updateMode: false,
      headerArray: [
        { text: "Id", value: "instituteId" },
        { text: "Custom Id", value: "customInstituteId" },
        { text: "Name", value: "instituteName" },
        { text: "Address", value: "instituteAddress" },
        { text: "Link", value: "registrationLink" },
        { text: " Fee", value: "onlineAdmissionFee" },
        { text: " Fee Type", value: "feeTakingType" },
        { text: "Image", value: "imageUrl" },
        { text: "", value: "actions" },
      ],
      image: null,

      formData: {
        customInstituteId: null,
        instituteName: null,
        instituteAddress: null,
        onlineAdmissionFee: 0,
        imageUrl: null,
        feeTakingType: null,
      },
      initFormData: {
        customInstituteId: null,
        instituteName: null,
        instituteAddress: null,
        onlineAdmissionFee: 0,
        feeTakingType: null,
      },
      formRules: {
        customInstituteId: [(v) => !!v || "Required"],
        instituteName: [(v) => !!v || "Required"],
        instituteAddress: [(v) => !!v || "Required"],
        feeTakingType: [(v) => !!v || "Required"],
      },
    };
  },
  methods: {
    saveBtnHandler() {
      this.saveInstitutionImage();
    },
    clear() {
      this.formData = { ...this.initFormData };
    },
    getAllInstitutes() {
      this.loadingState = true;
      this.$http
        .get(api.GET_ALL_INSTITUTES)
        .then((res) => {
          this.institutes = res.data.payLoad.content.map((e) => {
            e["registrationLink"] = `${this.portalLink}/${e.customInstituteId}`;
            return e;
          });

          console.log(this.institutes);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getAllFeeTakingTypes() {
      this.loadingState = true;
      this.$http
        .get(api.GET_ALL_FEE_TAKING_TYPE)
        .then((res) => {
          this.feeTakingTypes = res.data.payLoad;
          console.log(this.feeTakingTypes);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    createInstitute() {
      this.loadingState = true;
      let url = this.updateMode ? api.UPDATE_INSTITUTES : api.CREATE_INSTITUTES;
      console.log("url===>", url, "formData===>", this.formData);
      this.$http
        .post(url, { ...this.formData })
        .then((res) => {
          alert("success");
          console.log(res.data);
          this.updateMode
            ? (this.institutes = this.institutes.map((e) => {
                if (e.instituteId == this.formData.instituteId) {
                  e = {
                    ...res.data.payLoad,
                    registrationLink: `${this.portalLink}/${e.customInstituteId}`,
                  };
                }
                return e;
              }))
            : this.institutes.push(res.data.payLoad);
          this.clear();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    editInstituteHandler(item) {
      let path = slug.EDIT_INSTITUTE + "/" + item.instituteId;
      this.$router.push(path);
    },
    fileHandler(event) {
      if (event instanceof File) {
        let reader = new FileReader();
        reader.readAsDataURL(event);
        reader.addEventListener("load", () => {
          this.formData.imageUrl = reader.result;
        });
      } else {
        this.formData.imageUrl = null;
      }
    },
    saveInstitutionImage() {
      let formData = new FormData();
      formData.append("instituteImage", this.image);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      if (this.image instanceof File) {
        let url = this.updateMode
          ? `${api.UPDATE_INSTITUTE_IMAGE}/${this.formData.instituteId}`
          : api.UPLOAD_INSTITUTE_IMAGE;

        this.loadingState = true;
        this.$http
          .post(api.UPLOAD_INSTITUTE_IMAGE, formData, config)
          .then((res) => {
            this.formData.imageUrl = res.data.payLoad;
            this.createInstitute();
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log("Image Error=======>", error.response);
          })
          .finally(() => {
            this.loadingState = false;
          });
      } else {
        this.createInstitute();
      }
    },
    editInstituteInformationHandler(item) {
      this.image = null;
      this.formData = { ...item };
      this.updateMode = true;
      window.scrollTo(0, 0);
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
