import Vue from "vue";
import VueRouter from "vue-router";
import * as slug from "./slug.js";

Vue.use(VueRouter);

import Home from "../pages/home.vue";
import EditInstitute from "../pages/editInstitute.vue";
import ExcelReport from "../pages/excelReport.vue";
import Login from "../pages/login.vue";

const route = (path, component) => {
  return { path, component };
};

const router = new VueRouter({
  mode: "history",

  routes: [
    route(slug.HOME, Home),
    route(slug.EXCEL_REPORT, ExcelReport),
    route(slug.LOGIN, Login),
    route(slug.EDIT_INSTITUTE + "/:instituteId", EditInstitute),
    // {
    //   path: slug.FACILITY,
    //   component: Facility,
    // },
  ],
});

export default router;
