const HOST = "http://143.244.128.66:8080";
// const HOST = "https://admission.dcampusbd.com";
// const HOST = "http://localhost:5000";
const PREFIX = "api/admin";
const BASE_URL = `${HOST}/${PREFIX}`;

export const GET_ALL_INSTITUTES = `${HOST}/api/v1/get-all-registered-institutes?pageNumber=0&pageSize=100`;
export const GET_ALL_INSTITUTES_FOR_AUTH_CHECK = `${HOST}/api/v1/get-all-registered-institutes?pageNumber=0&pageSize=1`;
export const GET_ALL_FEE_TAKING_TYPE = `${HOST}/api/v1/dropDownList/fee-types`;

export const CREATE_INSTITUTES = `${HOST}/api/v1/register-institute`;
export const UPDATE_INSTITUTES = `${HOST}/api/v1/update-institute-info`;
export const UPLOAD_INSTITUTE_IMAGE = `${HOST}/api/v1/upload-institute-image`;
export const UPDATE_INSTITUTE_IMAGE = `${HOST}/api/v1/update-institute-image`; /* /{instituteId} */
export const REGISTER_NEW_USER_FOR_INSTITUTE = `${HOST}/api/v1/register-user-for-institute`; /* /:instituteId */

export const DOWNLOAD_EXCEL_REPORT = `${HOST}/api/v1/get-excel-report`;
export const LOGIN = `${HOST}/api/login`;
