<template>
  <div>
    <v-btn @click="download" color="primary" class="mx-auto"
      >Download Excel Report</v-btn
    >
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {
    download() {
      this.$http
        .get(api.DOWNLOAD_EXCEL_REPORT)
        .then((res) => {
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {});
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
</style>