<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <v-row class="pa-12">
        <v-col
          cols="6"
          class="mx-auto px-8 py-12"
          style="border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 10px"
        >
          <v-card-title>User For Institute</v-card-title>
          <v-form ref="form" v-model="valid">
            <v-row>
              <!-- Custom Id -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  v-model="formData.userName"
                  outlined
                  label="Username"
                  class="form_element"
                  :rules="formRules.userName"
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>
              <!-- Institute Name -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  v-model="formData.password"
                  outlined
                  label="Password"
                  class="form_element"
                  :rules="formRules.password"
                ></v-text-field>
                <span class="text-red">*</span>
              </v-col>
              <!-- Buttons -->
              <v-col cols="12" class="text-center">
                <v-btn
                  @click="saveUserBtnHandler"
                  :disabled="!valid"
                  color="primary"
                  class="mx-2"
                >
                  Save
                </v-btn>
                <v-btn @click="clear" color="error" class="mx-2">Clear</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.instituteId = this.$route.params.instituteId;
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,

      valid: false,
      instituteId: null,
      formData: {
        userName: null,
        password: null,
        isAccountEnabled: true,
      },
      initFormData: {
        userName: null,
        password: null,
        isAccountEnabled: true,
      },
      formRules: {
        userName: [(v) => !!v || "Required"],
        password: [(v) => !!v || "Required"],
      },
    };
  },
  methods: {
    saveUserBtnHandler() {
      this.loadingState = true;
      this.$http
        .post(api.REGISTER_NEW_USER_FOR_INSTITUTE + "/" + this.instituteId, {
          ...this.formData,
        })
        .then((res) => {
          alert("Success");
          this.clear();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.$router.push("/login");
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    clear() {
      this.formData = { ...this.initFormData };
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
</style>